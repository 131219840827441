<app-header></app-header>


<!--Banner principal-->
<section class="banner-p">
  <img src="assets/img/corp/banner.png" width="100%" alt="">
  <div class="banner-text">
  
  </div>
</section>
<section class="background-gray text-center">
  <br><br>
  <h1 class="text-pink">IMPULSA EL MÁXIMO&nbsp; <label class="bold">POTENCIAL</label></h1>
  <h1 class="text-pink bold">DE TUS COLABORADORES</h1>
  <br>
  <h3 style="width: 60%;margin-left: 20%;font-weight: bold;">
      Estás a un click de mejorar el bienestar, la creatividad y la
      productividad de tus empleados
  </h3>
  <br>
  <form  (ngSubmit)="register(fRegister)" #fRegister="ngForm"> 
      <div class="container background-gray">
         
          <div class="row justify-content-md-center ">
              <div class="col-sm " >
                  <div class="form-group">
                      <input type="text" placeholder="Nombre" clearInput class="form-control"  [(ngModel)]="user.name" [ngModelOptions]="{standalone:true}" required>
                   </div>
              </div>
              <div class="col-sm " >
                  <div class="form-group">
                      <input type="email" placeholder="E-mail" clearInput class="form-control"  [(ngModel)]="user.email" [ngModelOptions]="{standalone:true}" required>
                    </div>
              </div>
              <div class="col-sm " >
                  <div class="form-group">
                      <input type="text" placeholder="TELÉFONO" maxlength="10" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"  clearInput class="form-control"  [(ngModel)]="user.phone" [ngModelOptions]="{standalone:true}" required>
                  </div>
              </div>
          </div>
          <div class="row justify-content-md-center ">
              <div class="col-sm-12 col-md-12 col-12  " >
                  <div class="text-center">
                      <label *ngIf="message != ''" >{{message}}</label>
                  </div>
              </div>
          </div>    
          <br>                
      </div>
      <div class="row justify-content-md-center ">
        <div class="col-sm-12 col-md-12 col-12  text-center" >
            <button class="btn btn-black" type="submit" >CONTÁCTANOS</button> 
        </div>
    </div>
  </form>
</section>
<section>
<div data-tf-widget="G7fZvmK1" data-tf-opacity="100" data-tf-inline-on-mobile data-tf-iframe-props="title=Empresa Saludable" data-tf-transitive-search-params data-tf-auto-focus data-tf-medium="snippet" data-tf-full-screen></div><script src="//embed.typeform.com/next/embed.js"></script> 
</section>
<section class="margin-section">
    <div class="container">
      <div class="row justify-content-md-center ">
        <div class="col-sm-12 col-md-12 col-12  " >
          <img lazyLoad="assets/img/corp/WELLNESS.png" alt="" width="100%">
        </div>
      </div>
      <div class="row justify-content-md-center">
        
        <div class="col-sm background-gray align-self-stretch container-center">
          <div>
            <br>
            <p class="justify padding-text text-size" >
              En <b>LIVE BODY CORP</b> encontrarás entrenamientos, recetas,
              meditaciones, asesorías y mucho más, para facilitar la 
              experiencia del desarrollo personal de cada uno de tus 
              colaboradores y ayudarlos a mitigar el estrés laboral.
            </p>
            <p class="justify padding-text text-size" >
                También somos una herramienta digital que ayuda en el cumplimiento 
                de la <b>NOM 035</b>, potenciando el talento humano de tu empresa.
            </p>
            
            <br>
            <div class="text-center"><a ><img lazyLoad="assets/img/btn/informacion.png" data-aos="zoom-in-up" alt="" width="50%"></a></div>
            <br><br>    
          </div>
        </div> 
        <div class="col-sm  background-gray  no-padding align-self-stretch container-center">
            <img lazyLoad="assets/img/corp/banner1.png" data-aos="fade-down" class="img-width" alt="" width="100%">
        </div>
      </div>
    </div>
</section>

<!--<section class="margin-section">
    <h2 class="text-center text-black bold">¿QUÉ OFRECE</h2>
    <h1 class="text-center text-pink bold">LIVE BODY CORP?</h1>
    <br><br>
    <div class="container container-width">
        <div class="row justify-content-md-center ">
          <div class="col-sm-4 col-md-4 col-12 align-self-stretch no-padding container-center" >
              <img lazyLoad="assets/img/corp/laptop.png" data-aos="zoom-in" width="100%" alt="">
              <br><br>
          </div>
          <div class="col-sm-8 col-md-8 col-12 align-self-stretch padding-side" >
              <img lazyLoad="assets/img/corp/icons.png" data-aos="zoom-in" width="100%"  alt="">
          </div>
        </div>
    </div>
</section>-->
<section class="margin-section">
  <div class="banner-p">
      <img lazyLoad="assets/img/corp/img-iconos.png" data-aos="zoom-in" alt="">
      
  </div>
</section>

<section class="margin-section">
    <div class="container">
      <div class="row justify-content-md-center ">
        <div class="col-sm-10 col-md-10 col-12  " >
          <img lazyLoad="assets/img/corp/BENEFICIOS.png"  alt="" width="100%">
        </div>
      </div>
      <div class="row justify-content-md-center relative">
        
        <div class="col-sm background-gray align-self-stretch container-center">
          <div>
            <br><br>
            <p class="justify padding-text text-size" >
              El empleado tendrá un espacio dedicado para su bienestar,
              donde podrá desarrollarse en diferentes actividades físicas 
              y teóricas que fortalezcan su cuerpo, mente y emociones.
            </p>
            <p class="justify padding-text text-size" >
                A tráves de la plataforma podrán adquirir nuevos
                hábitos más sanos que mejoren su condición y su salud,
                ayúdandolo a sentirse más pleno y motivado para alcanzar
                nuevas metas en su vida laboral y personal.
            </p>
            
            <br>

          </div>
        </div> 
        <div class="col-sm no-padding background-gray  align-self-stretch container-center">
            <img lazyLoad="assets/img/corp/banner2.png" data-aos="fade-up" class="img-width" alt="" width="100%">
        </div>
        <img lazyLoad="assets/img/btn/empleado.png" data-aos="zoom-in-up" width="50%" alt="" class="btn-absolute"> 
      </div>
      <div class="row justify-content-md-center">
        <div class="col-sm  align-self-stretch " style="padding-left: 0px;">
          <div class="text-center  background-gray container-icon" >
            <br><br>
            <img lazyLoad="assets/img/corp/book.png" width="70px" data-aos="fade-down" alt="">
            <br><br>
            <h4 class="bold">APRENDIZAJE</h4>
            <br><br>
            <p class="text-center padding-text " >
                Podrán desarrollar nuevas habilidades y
                conocimientos por medio de las capacitaciones 
                en línea.
            </p>
          </div>
          
        </div>
        <div class="col-sm  align-self-stretch text-center ">
          <div class="text-center margin-side-block background-gray container-icon">
            <br><br>
            <img lazyLoad="assets/img/corp/healthcare.png" width="70px" data-aos="fade-down" alt="">
            <br><br>
            <h4 class="bold">SALUD</h4>
            <br><br>
            <p class="text-center  padding-text " >
                A tráves de los entrenamientos físicos y la 
                asesoria nutricional podrán mejorar 
                hábitos que impacten positivamente en su salud.
            </p>
          </div>
          
        </div>
        <div class="col-sm  align-self-stretch  ">
          <div class="text-center margin-side-block background-gray container-icon">
            <br><br>
            <img lazyLoad="assets/img/corp/jump.png" width="70px" data-aos="fade-down" alt="">
            <br><br>
            <h4 class="bold">FELICIDAD</h4>
            <br><br>
            <p class="text-center padding-text " >
                La meditación y los cursos sobre 
                manejo de las emociones los ayudarán 
                a sentirse más plenos y felices.
            </p>
          </div>
          
        </div>
        <div class="col-sm  align-self-stretch " style="padding-right: 0px;">
          <div class="text-center  background-gray container-icon">
            <br><br>
            <img lazyLoad="assets/img/corp/success.png" width="69px" data-aos="fade-down" alt="">
            <br><br>
            <h4 class="bold">SUPERACIÓN <br> <font class="text-pink bold">PERSONAL</font></h4>
            
            <p class="text-center padding-text " style="padding-top: 18px;" >
                Podrán seguir aprendiendo y creciendo de manera 
                personal y profesional con clases en línea
                que se adapten a sus horarios y estilo de vida.
            </p>
          </div>
          
        </div>
      </div>
      <div class="row justify-content-md-center ">
        <div class="col-sm text-center" >
            <br>
            <a routerLink="/contact"><img lazyLoad="assets/img/btn/informacion.png" data-aos="zoom-in-up" alt="" width="30%"></a>
        </div>
      </div>
    </div>
</section>

<section class="margin-section">
    <div class="container">
      <div class="row justify-content-md-center ">
        <div class="col-sm-10 col-md-10 col-12  " >
          <img lazyLoad="assets/img/corp/BENEFICIOS.png" alt="" width="100%">
        </div>
      </div>
      <div class="row justify-content-md-center relative">
        
        <div class="col-sm background-gray align-self-stretch container-center">
          <div>
            <br>
            <p class="justify padding-text text-size" >
              Al promover un entorno organizacional favorable y prevenir 
              o controlar las condiciones que pudieran afectar la salud 
              mental de los trabajadores, tendrás como resultado un clima 
              laboral más saludable, empleados más felices, motivados, 
              comprometidos y productivos.
            </p>
            <p class="justify padding-text text-size" >
                Gracias a las herramientas implementadas en la plataforma, 
                podrás realizar de manera digital los cuestionarios para 
                cumplir con la NOM 035, además de brindar seguimiento a los 
                casos de riesgo a través de nuestros expertos en nutrición
                y psicología.
            </p>
            
            <br>

          </div>
        </div> 
        <div class="col-sm background-gray  no-padding align-self-stretch ">
            <img lazyLoad="assets/img/corp/banner3.png" data-aos="fade-down" class="img-width" alt="" width="100%">
        </div>
        <img lazyLoad="assets/img/btn/empresa.png" data-aos="zoom-in-up" width="50%" alt="" class="btn-absolute"> 
      </div>
      <div class="row justify-content-md-center">
        <div class="col-sm align-self-stretch " style="padding-left: 0px;">
          <div class="text-center background-gray container-icon">
            <br><br>
            <img lazyLoad="assets/img/corp/medical-insurance.png" height="70px" data-aos="zoom-in" alt="">
            <br><br>
            <h4 class="bold">PREVENCIÓN</h4>
            <br><br>
            <p class="text-center padding-text " >
                Evitar incrementos por accidentes de 
                trabajo, relacionados por riesgos psicosociales.
            </p>
          </div>
          
        </div>
        <div class="col-sm  align-self-stretch ">
          <div class="text-center margin-side-block background-gray container-icon">
            <br><br>
            <img lazyLoad="assets/img/corp/handshake.png" height="70px" data-aos="zoom-in" alt="">
            <br><br>
            <h4 class="bold">FIDELIDAD</h4>
            <br><br>
            <p class="text-center padding-text " >
                Prevención de la renuncia psicológica, ausentismo, rotación 
                y baja lealtad de los trabajadores.
            </p>
          </div>
          
        </div>
        <div class="col-sm align-self-stretch  ">
          <div class="text-center margin-side-block background-gray container-icon">
            <br><br>
            <img lazyLoad="assets/img/corp/productivity.png" height="70px" data-aos="zoom-in" alt="">
            <br><br>
            <h4 class="bold">PRODUCTIVIDAD</h4>
            <br><br>
            <p class="text-center padding-text " >
                Trabajadores más sanos y productivos.
            </p>
          </div>
          
        </div>
        <div class="col-sm  align-self-stretch " style="padding-right: 0px;">
          <div class="text-center  background-gray container-icon"> 
            <br><br>
            <img lazyLoad="assets/img/corp/auction.png" height="70px" data-aos="zoom-in" alt="">
            <br><br>
            <h4 class="bold">CUMPLIMIENTO <br> <font class="text-pink bold">NOM 035</font></h4>
            
            <p class="text-center padding-text " style="padding-top: 20px;">
                Aplicación automática de los cuestionarios 
                correspondientes de la NOM 035, reportes 
                mensuales y chat anónimo con los empleados.
            </p>
          </div>
          
        </div>
      </div>
      <div class="row justify-content-md-center ">
        <div class="col-sm text-center" >
            <br>
            <a routerLink="/contact"><img lazyLoad="assets/img/btn/cotizar.png" data-aos="zoom-in-up" alt="" width="30%"></a>
        </div>
      </div>
    </div>
</section>



<section>
  <div class="banner-p">
      <a routerLink="/contact"><img lazyLoad="https://disruptsoft.net/livebody_home/assets/img/corp/banner-sub2.png" data-aos="zoom-in" alt="" width="100%"></a>
      
  </div>
</section>



<section class="background-gray">
    <br><br>
    <div class="banner-p padding-side">
        <img lazyLoad="assets/img/corp/title-live2.png" data-aos="zoom-in-up" alt="">
    </div>
</section>

<app-footer></app-footer>